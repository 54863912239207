@tailwind base;
@tailwind components;
@tailwind utilities;

/* Add these styles to your existing CSS */
html, body, #root {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
}

#root {
  display: flex;
  flex-direction: column;
}

.flex-grow {
  flex: 1 0 auto;
}

footer {
  flex-shrink: 0;
}
